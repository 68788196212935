// extracted by mini-css-extract-plugin
export var characterDescription = "styles-module--character-description--2wBhf";
export var characterHr = "styles-module--character-hr--Ay8SP";
export var characterImg = "styles-module--character-img--8s82y";
export var characterLabel = "styles-module--character-label--AptYB";
export var characterLeft = "styles-module--character-left--+Urhz";
export var characterLink = "styles-module--character-link--+A+R1";
export var characterReleated = "styles-module--character-releated--o+dVU";
export var characterRight = "styles-module--character-right--hq6sk";
export var characterStat = "styles-module--character-stat--3MHWU";
export var characterStats = "styles-module--character-stats--cIjFR";
export var characterTown = "styles-module--character-town--hplwt";
export var characterTownIcon = "styles-module--character-town-icon--1-HVm";
export var characterWrapper = "styles-module--character-wrapper--cpeVh";
export var characterYellow = "styles-module--character-yellow--gL6Xq";